import { urls } from "../../../global/UrlConstants";
import { getRequest, postRequest } from "../../../global/utils";
import { ManglikStatusPayload } from "./manglikStatus.types";

export const checkManglikStatus = async (formData: ManglikStatusPayload) => {
    return postRequest(urls.checkManglikStatus, formData)
}

export const searchBirthCityDetail = async (searchText: String) => {
    return getRequest(`${urls.searchBirthCityDetail}/${searchText}`)
}