import { Stack, Typography } from '@mui/material';
import { ChangeEventHandler } from 'react'

interface CustomDateTimePickerProps {
    name: string,
    width?: string | number,
    label: string,
    value: string;
    handleChange: ChangeEventHandler<HTMLInputElement>
}

const CustomDateTimePicker = ({ name, width, label, value, handleChange }: CustomDateTimePickerProps) => {
    return (
        <Stack >
            <Typography
                fontSize={"12px"}>
                {label}
            </Typography>
            <Stack >
                <input
                    type="datetime-local"
                    name={name}
                    value={value}
                    onChange={handleChange}
                    style={{
                        width: width ? width : 200,
                        padding: '10px',
                        fontSize: '16px',
                        outline: 'none',
                        transition: 'border-color 0.3s, box-shadow 0.3s',
                    }}
                    onFocus={(e) => e.target.style.boxShadow = '0 4px 8px rgba(0,0,0,0.3)'}
                    onBlur={(e) => e.target.style.boxShadow = '0 2px 4px rgba(0,0,0,0.2)'}
                />
            </Stack>
        </Stack>
    )
}

export default CustomDateTimePicker;