const formStyles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    acordionDetails: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: "flex-start",
        padding: '15px',
        gap: "5px"
    },
    addField: {
        display: 'flex',
        flexDirection: 'row-reverse'
    }
}

export default formStyles;