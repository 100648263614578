import frameStyle from './Frame.styles';
import { Box, ImageList, ImageListItem } from '@mui/material';

const frames = [
    { id: 0, name: 'Frame0.jpg', src: '/images/template/Frame0.jpg', price: 0 },
    { id: 1, name: 'Frame1.jpg', src: '/images/template/Frame1.jpg', price: 10 },
    { id: 2, name: 'Frame2.jpg', src: '/images/template/Frame2.jpg', price: 20 },
    { id: 3, name: 'Frame3.jpg', src: '/images/template/Frame3.jpg', price: 30 },
    // { id: 4, name: 'Frame4.jpg', src: '/images/template/Frame4.jpg', price: '40' },
    { id: 5, name: 'Frame5.jpg', src: '/images/template/Frame5.jpg', price: 50 },
    // { id: 7, name: 'Frame7.jpg', src: '/images/template/Frame7.jpg', price: '60' },
    { id: 8, name: 'Frame8.jpg', src: '/images/template/Frame8.jpg', price: 70 },
    { id: 9, name: 'Frame9.jpg', src: '/images/template/Frame9.jpg', price: 80 },
    { id: 10, name: 'Frame10.jpg', src: '/images/template/Frame10.jpg', price: 90 },

    // { id: 11, name: 'Frame11.png', src: '/images/template/Frame11.png', price: '100' },
    // { id: 12, name: 'Frame12.png', src: '/images/template/Frame12.png', price: '110' },
    // { id: 13, name: 'Frame13.png', src: '/images/template/Frame13.png', price: '120' },

]


const Frame = (props: any) => {
    const classes = frameStyle;

    const handleScroll = (event: any) => {
        const container = event.target;
        const scrollAmount = event.deltaY;
        container.scrollTo({
            top: 0,
            left: container.scrollLeft + scrollAmount,
            behavior: 'smooth'
        });
    };

    return (
        <ImageList cols={4} gap={8} style={{ padding: '10px' }} >
            {frames.map((item: any) => (
                <ImageListItem key={item.img}>
                    <Box
                        style={classes.imageContainer}
                        onClick={() => props.handleFrameChange(item)}
                    >
                        <img
                            style={classes.image}
                            srcSet={`${item.src}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                            src={`${item.src}?w=161&fit=crop&auto=format`}
                            alt={item.name}
                            loading="lazy"
                        />
                        <Box
                            sx={classes.price}
                        >
                            &#8377;{item.price}
                        </Box>
                    </Box>
                </ImageListItem>
            ))}
        </ImageList>
    )
}

export default Frame;