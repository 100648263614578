import React from 'react'
import footerStyles from './Footer.styles'
import { Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export default function Footer() {
    const classes = footerStyles;
    const navigate = useNavigate();

    return (
        <>
            <Stack sx={classes.mainFooter}>
                © {new Date().getFullYear()} QuickDocSolution. All rights reserved.
                <Stack direction='row' spacing={1} p={1}>
                    <Typography style={{ cursor: 'pointer' }} color='black' fontWeight='bold' onClick={() => navigate('/privacy-policy')}>Privacy Policy</Typography>
                    <Typography style={{ cursor: 'pointer' }} color='black' fontWeight='bold' onClick={() => navigate('/refund-policy')}>Refund Policy</Typography>
                </Stack>
            </Stack>
        </>
    )
}
