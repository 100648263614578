import { Box, Stack, Typography } from '@mui/material';
import headerStyles from './Header.styles'
import { useNavigate } from 'react-router-dom';

const Header = () => {
    const classes = headerStyles;
    const navigate = useNavigate();
    return (
        <Box style={classes.header}>
            <Stack direction='row' onClick={() => navigate('/')}>
                <Typography style={classes.typography}>Quick</Typography>
                <Typography style={classes.typography} color='tomato'>Doc</Typography>
                <Typography style={classes.typography}>Solutions</Typography>
                <Typography style={classes.typography}>.com</Typography>
            </Stack>
            <Typography 
            onClick={() => navigate('/manglik-status')}
            style={{
                fontSize: '1.2rem',
                margin: '0 16px',
                fontWeight: 600,
                color: '#FF5733'
            }}>
                Check Manglik Status
            </Typography>
        </Box>
    )
}

export default Header;