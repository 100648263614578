import { Autocomplete, TextField } from '@mui/material'
import React, { ChangeEventHandler } from 'react'
import { Gender } from '../manglikStatus.types'

interface CustomAutoCompleteProps {
    value: any,
    options: any[],
    getOptionLabel: any,
    width: string | number,
    onChange: ChangeEventHandler<HTMLInputElement>,
    renderInput?: any
}

export default function CustomAutoComplete({ value, options, getOptionLabel, width, onChange, renderInput }: CustomAutoCompleteProps) {
    return (
        <Autocomplete
            value={value}
            onChange={(event, value) => onChange(value)}
            options={options}
            getOptionLabel={getOptionLabel}
            renderInput={
                renderInput}
        />
    )
}
