import viewStyles from './View.styles'
import { Box, Grid, Paper, Stack, Typography } from '@mui/material';
import { Field, SubField } from '../../../global/Types';
import { useState } from 'react';
import styled from 'styled-components';
import { countLines } from '../../../global/helper';

const StyledTypography = styled(Typography)(({ theme }) => ({
    maxWidth: '75%',
    overflowWrap: 'break-word',
    wordBreak: 'break-word',
    whiteSpace: 'normal',
    overflow: 'hidden'
}));

const View = ({ paddingTop, secondPagepaddingTop, photoHeight, fieldSize, subFieldSize, formData, className, height, width }: any) => {
    const classes = viewStyles;
    const [componentHeight, setcomponenytHeight] = useState(height);
    const [isNewPageContent, setIsNewPageContent] = useState(false)
    const [breakpoint, setBreakPoint] = useState({ outerIndex: 0, innerIndex: 0 });
    const [page, setPage] = useState(0);

    const fontStyle = {
        fontFamily: formData.font.fontFamily,
        fontStyle: formData.font.fontStyle,
        fontWeight: formData.font.fontWeight
    }

    function filterArray(data: Field[], startOuterIndex: number, startInnerIndex: number, endOuterIndex: number, endInnerIndex: number) {
        const result = [];
        for (let outerIndex = startOuterIndex; outerIndex <= endOuterIndex; outerIndex++) {
            const outerItem = data[outerIndex];
            if (!outerItem) continue;
            const start = (outerIndex === startOuterIndex) ? startInnerIndex : 0;
            const end = (outerIndex === endOuterIndex) ? endInnerIndex : outerItem.subfield.length - 1;
            const filteredSubfields = outerItem.subfield.slice(start, end + 1);
            result.push({
                ...outerItem,
                subfield: filteredSubfields
            });
        }

        return result;
    }

    function findLineCount(formData: any) {
        let cumulativeCount = 0;

        for (const outerItem of formData) {
            for (const innerItem of outerItem.subfield) {
                cumulativeCount++;
                if (cumulativeCount === 16) {
                    return {
                        outerId: outerItem.id,
                        innerId: innerItem.id
                    };
                }
            }
        }

        return null;
    }

    const addVerticalSpace = (outerIndex: number, innerIndex: number) => {
        let line = 18;
        let photoVisibility = formData?.primaryPhoto?.visibility;
        if (photoVisibility) {
            line = 12;
        }
        let returnValue: JSX.Element;
        if (countLines(filterArray(formData.fields, 0, 0, outerIndex, innerIndex)) === line) {
            returnValue = <Box style={{ paddingTop: '180px' }}></Box>
        } else {
            returnValue = <Box style={{ paddingTop: '0px' }}></Box>
        }

    }

    const checkIsNewPageContent = (outerIndex: number, innerIndex: number) => {

        let line = 18;
        let photoVisibility = formData?.primaryPhoto?.visibility;
        if (photoVisibility) {
            line = 13;
        }
        if (countLines(filterArray(formData.fields, 0, 0, outerIndex, innerIndex)) === line) {
            if (!isNewPageContent) {
                setcomponenytHeight(height * 2);
                setIsNewPageContent(true);
                setBreakPoint({ outerIndex: outerIndex, innerIndex: innerIndex + 1 })
            }
        }

        let returnValue: JSX.Element;
        if (countLines(filterArray(formData.fields, 0, 0, outerIndex, innerIndex)) === line) {
            returnValue = <Box style={{ paddingTop: '180px' }}></Box>
        } else {
            returnValue = <Box style={{ paddingTop: '0px' }}></Box>
        }
        return <Box style={{ paddingTop: '0px' }}></Box>;
    }

    const itrateFields = (data: any) => {
        return data.map((field: Field, outerIndex: number) => {
            return <>
                <Box
                    style={{
                        width: '85%',
                        overflow: 'hidden',
                        paddingTop: '2px'
                    }}>
                    <Typography fontSize={fieldSize} className='title' style={{ ...fontStyle, fontWeight: 'bolder' }}>{field.name}</Typography>
                    {field.subfield.map((s: SubField, innerIndex: number) => {
                        let arr: any = [];
                        if (s.visibility) {
                            arr.push(<Grid item xs={5} sm={5} md={5} lg={5} xl={5}>
                                <StyledTypography style={fontStyle} fontSize={subFieldSize}>{s.key}</StyledTypography>
                            </Grid>)
                            if (s.value) {
                                arr.push(<Grid item xs={0.5} sm={0.5} md={0.5} lg={0.5} xl={0.5}>
                                    <StyledTypography style={fontStyle} fontSize={subFieldSize}>:</StyledTypography>
                                </Grid>)
                            }
                            arr.push(<Grid item xs={6.5} sm={6.5} md={6.5} lg={6.5} xl={6.5}>
                                <StyledTypography
                                    style={fontStyle} fontSize={subFieldSize}>{s.value}</StyledTypography>
                            </Grid>)
                        }
                        arr.push(checkIsNewPageContent(outerIndex, innerIndex))
                        return <Grid container >{arr}</Grid>;
                    })}
                </Box>
            </>
        })
    }

    const component = () => {
        return <Paper elevation={12}>
            <Stack direction='column' height='80vh' overflow='scroll' style={{ height: '80vh', overflowX: 'hidden', scrollbarWidth: 'thin', scrollbarColor: "grey #f1f1f1" }}>
                <Stack className={className + 1}
                    style={{
                        backgroundImage: `url("/images/frames/${formData.frame.name}")`, //need to uncomment
                        backgroundSize: `${width}px ${height}px`, backgroundRepeat: `repeat-y`,
                        width: `${width}px`,
                        height: `${height}px`,
                    }}
                >
                    <Box
                        style={{
                            position: 'relative',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-end',
                            width: `${width}px`,
                            height: `${componentHeight}px`,
                        }}>
                        <Box sx={{ ...classes.heading, paddingTop: paddingTop }}>
                            {formData?.topHeader?.visibility && (
                                <Typography fontSize={subFieldSize}>{formData.topHeader.value}</Typography>
                            )}

                            {formData?.logo.visibility && (
                                <img src={formData.logo.src} alt={formData.logo.alt} style={{ width: 30, height: 30 }} />
                            )}
                            {formData?.bottomHeader?.visibility && (
                                <Typography>{formData.bottomHeader.value}</Typography>
                            )}
                        </Box>
                        <Box sx={{ ...classes.photo }}>
                            {formData?.primaryPhoto?.value && formData?.primaryPhoto?.visibility && (
                                <img src={formData?.primaryPhoto?.value} alt={formData.logo.alt} style={{ width: 'auto', height: photoHeight, border: '1px solid black', borderRadius: '100px' }} />
                            )}
                        </Box>
                        {isNewPageContent ? itrateFields(filterArray(formData.fields, 0, 0, breakpoint.outerIndex, breakpoint.innerIndex - 1)) : itrateFields(formData.fields)}
                    </Box>
                </Stack>
                {isNewPageContent && <Stack className={className + 2}
                    style={{
                        backgroundImage: `url("/images/frames/${formData.frame.name}")`, //need to uncomment
                        backgroundSize: `${width}px ${height}px`, backgroundRepeat: `repeat-y`,
                        width: `${width}px`,
                        height: `${height}px`,
                        paddingTop: secondPagepaddingTop
                    }}
                >
                    <Box
                        style={{
                            paddingTop: '65px',
                            position: 'relative',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-end',
                            width: `${width}px`,
                            height: `${componentHeight}px`,
                        }}>
                        {itrateFields(filterArray(formData.fields, breakpoint.outerIndex, breakpoint.innerIndex, formData.fields.length - 1, formData.fields[formData.fields.length - 1].subfield.length - 1))}
                    </Box>
                </Stack>}

                {formData.multiplePhoto.value.length > 0 && formData.multiplePhoto.visibility && <Stack
                    className={className + 3}
                    style={{
                        backgroundImage: `url("/images/frames/${formData.frame.name}")`, //need to uncomment
                        backgroundSize: `${width}px ${height}px`, backgroundRepeat: `repeat-y`,
                        width: `${width}px`,
                        height: `${height}px`,
                        minHeight: `${height}px`,
                    }}
                    justifyContent='center'
                >
                    <Stack display='flex' flexDirection='row' flexWrap='wrap' justifyContent='center' >
                        {formData?.multiplePhoto?.visibility && (
                            formData?.multiplePhoto?.value.map((e: any) => {
                                return <img
                                    style={{ margin: '1px', width: 'auto', height: photoHeight, border: '1px solid black', borderRadius: '6px' }}
                                    src={e}
                                    alt="Preview"
                                />
                                // return <img src={e} alt={formData.logo.alt} style={{ margin: '10px', width: 'auto', height: '104px', border: '1px solid black', borderRadius: '6px' }} />
                            })
                        )}
                    </Stack>
                </Stack>}
            </Stack>
        </Paper>
    }

    return component();
}

export default View;