import { styled, Typography } from "@mui/material";

export const CustomHeading = styled(Typography)(({ theme }) => ({
    display: 'flex', justifyContent: 'center',
    padding: theme.spacing(2),
    fontSize: '2.5rem', // Larger font size for emphasis
    fontWeight: 600, // Slightly less bold than 700
    color: theme.palette.text.primary, // Use the primary text color from the theme
    textAlign: 'center', // Center-align the text
    background: `linear-gradient(45deg, ${theme.palette.secondary.light} 30%, ${theme.palette.secondary.dark} 90%)`, // Gradient background
    WebkitBackgroundClip: 'text', // Gradient effect for text
    marginBottom: theme.spacing(4), // Margin below the heading
}));