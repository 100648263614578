const dummyRealFormData = {
  logo: {
    "id": 1,
    "src": "/images/god-icon/1.png",
    "alt": "Logo 1",
    visibility: true
  },
  frame: {name: '', price: 0},
  font: { id: 1, fontFamily: 'Times', fontStyle: '', fontWeight: '' },
  topHeader: { value: '|| Shree Ganesh ||', visibility: true },
  bottomHeader: { value: 'BIO DATA', visibility: true },
  primaryPhoto: { value: '/images/dummy/photo.jpg', visibility: true },
  multiplePhoto: { value: ['/images/dummy/photo.jpg', '/images/dummy/photo.jpg', '/images/dummy/photo.jpg', '/images/dummy/photo.jpg', '/images/dummy/photo.jpg'], visibility: true },
  fields: [
    {
      id: 1, name: "Personal Details", subfield: [
        {
          id: 0, key: 'Name', value: 'Ravi Kumar Sharma', visibility: true
        },
        {
          id: 2, key: 'Date of Birth', value: '5 Jan 1995', visibility: true
        },
        {
          id: 3, key: 'Place of Birth', value: 'New Delhi', visibility: true
        },
        {
          id: 4, key: 'Rashi', value: 'Tula (Libra)', visibility: true
        },
        {
          id: 5, key: 'Nakshatra', value: 'Rohini', visibility: true
        },
        {
          id: 6, key: 'Complexion', value: 'Very Fair', visibility: true
        },
        {
          id: 7, key: 'Height', value: '5 feet 5 inches', visibility: true
        },
        {
          id: 8, key: 'Education', value: "Bachelor''s", visibility: true
        },
        {
          id: 9, key: 'Occupation', value: "Software Developer", visibility: true
        }
      ]
    },
    {
      id: 2, name: "Family Details", subfield: [
        {
          id: 0, key: 'Father Name', value: 'Ram Prem Sharma', visibility: true
        },
        {
          id: 1, key: 'Father Occupation', value: 'Electrician', visibility: true
        },
        {
          id: 2, key: 'Mother Name', value: 'Pinki Sharma', visibility: true
        },
        {
          id: 3, key: "Mother's Occupation", value: 'Homemaker', visibility: true
        }
      ]
    },
    {
      id: 3, name: "Contact Details", subfield: [
        {
          id: 0, key: 'Contact Number', value: '+91-8051693666', visibility: true
        },
        {
          id: 1, key: 'Address', value: 'Sec-3, Gautam Buddha Nagar, Noida, 140407', visibility: true
        }
      ]
    },
  ]
};

const dummyBlankFormData = {
  logo: {
    "id": 1,
    "src": "/images/god-icon/1.png",
    "alt": "Logo 1",
    visibility: true
  },
  frame: {name: '', price: 0},
  font: { id: 1, fontFamily: '', fontStyle: '', fontWeight: '' },
  topHeader: { value: '|| Shree Ganesh ||', visibility: true },
  bottomHeader: { value: 'BIO DATA', visibility: true },
  primaryPhoto: { value: '', visibility: true },
  multiplePhoto: { value: [], visibility: true },
  fields: [
    {
      id: 1, name: "Personal Details", subfield: [
        {
          id: 0, key: 'Name', value: '', visibility: true
        },
        {
          id: 1, key: 'Date of Birth', value: '', visibility: true
        },
        {
          id: 3, key: 'Occupation', value: "", visibility: true
        }
      ]
    },
    {
      id: 2, name: "Family Details", subfield: [
        {
          id: 0, key: 'Father Name', value: '', visibility: true
        },
        {
          id: 1, key: 'Father Occupation', value: '', visibility: true
        },
        {
          id: 2, key: 'Mother Name', value: '', visibility: true
        },
      ]
    },
    {
      id: 3, name: "Contact Details", subfield: [
        {
          id: 0, key: 'Contact Number', value: '', visibility: true
        },
        {
          id: 1, key: 'Address', value: '', visibility: true
        }
      ]
    }
  ]
};

export { dummyRealFormData, dummyBlankFormData };