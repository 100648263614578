const viewManglikStatusStyles = {
    container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        backgroundColor: '#f4f4f9',
    },
    card: {
        backgroundColor: '#ffffff',
        borderRadius: '8px',
        boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
        padding: '20px',
        maxWidth: '600px',
        width: '100%',
    },
    title: {
        marginBottom: '20px',
        fontSize: '24px',
        color: '#333'
    },
    list: {
        listStyleType: 'none',
        padding: 0,
        margin: 0
    },
    listItem: {
        padding: '10px 0',
        borderBottom: '1px solid #ddd',
        fontSize: '16px',
        color: '#555'
    },
    typography: {
        fontWeight: "bold",
        display: "inline"
    }
};

export default viewManglikStatusStyles;