const calculatedPricePopupStyles = {
    dialogTitle: {
        backgroundColor: '#f5f5f5',
        color: '#333',
        fontWeight: 'bold',
        textAlign: 'center',
        borderBottom: '1px solid #ddd',
    },
    content: {
        padding: '20px',
        marginTop: "20px",
        border: '1px solid #ddd',
        borderRadius: '8px',
        backgroundColor: '#fff',
    },
    header: {
        margin: '0',
        fontSize: '1.5rem',
        color: '#333',
    },
    text: {
        margin: '10px 0',
        fontSize: '1rem',
        color: '#555',
    },
    separator: {
        margin: '10px 0',
        borderColor: '#ddd',
    },
    actions: {
        padding: '16px',
        borderTop: '1px solid #ddd',
    },
    closeButton: {
        backgroundColor: '#f44336',
        color: '#fff',
        borderRadius: '4px',
        fontSize: '16px',
        fontWeight: 'bold',
        textTransform: 'uppercase',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
        transition: 'background-color 0.3s, transform 0.3s',
    },
};

export default calculatedPricePopupStyles;