import axios from 'axios';

const data = {
    "id": "62939a62bafb4a28acd6ed49fe3b406a",
    "user": "https://test.instamojo.com/v2/users/ac46dc5a6b534300a9a3a3aa9350c3a5/",
    "phone": "+919089786756",
    "email": "ajith@instamojo.com",
    "buyer_name": "Ajith",
    "amount": "20.00",
    "purpose": "Test",
    "status": "Pending",
    "payments": [],
    "send_sms": false,
    "send_email": false,
    "sms_status": null,
    "email_status": null,
    "shorturl": null,
    "longurl": "https://test.instamojo.com/@ravi_sharma/62939a62bafb4a28acd6ed49fe3b406a",
    "redirect_url": "http://www.google.com/",
    "webhook": "https://webhook.site/bb97ae83-a108-4c27-89b7-f2387ab95a6b",
    "scheduled_at": null,
    "expires_at": null,
    "allow_repeated_payments": false,
    "mark_fulfilled": true,
    "created_at": "2024-08-22T06:56:39.121454Z",
    "modified_at": "2024-08-22T06:56:39.121469Z",
    "resource_uri": "https://test.instamojo.com/v2/payment_requests/62939a62bafb4a28acd6ed49fe3b406a/"
};

export default function InstamojoPayButton({ data }: any) {

    const proceedToPayment = async () => {
        const paymentURL = "http://localhost:8080/instamojo/payment/request";
        const response = await axios.post(paymentURL, data);
        console.log(response);
        // window.open('https://www.example.com', '_blank');
    }

    return (
        <>
            <button onClick={proceedToPayment}>Process Payment</button>
        </>
    )
}