import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import RefundPolicy from './screens/policy/RefundPolicy';
import PrivacyPolicy from './screens/policy/PrivacyPolicy';
import Home from './screens/home/Home';
import BioDataMaker from './screens/bioDataMaker/BioDataMaker';
import ManglikStatus from './screens/bioDataMaker/components/manglikStatus/ManglikStatus';
import Instamojo from './screens/bioDataMaker/payment/instamojo/InstamojoPayButton';
import ViewManglikStatus from './screens/bioDataMaker/components/manglikStatus/ViewManglikStatus';
import { SnackbarProvider } from 'notistack';
import DownloadBioData from './screens/bioDataMaker/components/downloadBioData/DownloadBioData';

function App() {
  return (
    <SnackbarProvider maxSnack={3}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/create-bio-data" element={<BioDataMaker />} />
          <Route path="/refund-policy" element={<RefundPolicy />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/manglik-status" element={<ManglikStatus />} />
          <Route path="/view-manglik-status" element={<ViewManglikStatus />} />
          <Route path="/instamojo" element={<Instamojo />} />
          <Route path="/download-biodata" element={<DownloadBioData />} />
        </Routes>
      </BrowserRouter>
    </SnackbarProvider>
  )
}

export default App;