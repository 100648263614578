const homeStyles = {
    mainStack: {
        background: 'url("images/home/cover.jpg")',
        backgroundSize: "cover",
    },
    mainHeading: {
        ...(window.innerWidth <= 601 && {
            fontSize: '28px'
        })
    },
    card: {
        width: '400px',
        height: '150px'
    },
    section: {
        background: 'url("images/home/cover.jpg")',
        backgroundSize: "cover",
        backgroundRepeat: 'no-repeat',
    },
    sampleImage: {
        width: '100%',
        height: 'auto'
    },
    body: {
        textAlign: 'justify !important',
        hyphens: 'auto',
        wordSpacing: '-.05em',
        padding: '0 2rem',
        lineHeight: '1.6',
        margin: '32px'
    },
}

export default homeStyles;