const frameStyle = {
    imageContainer: {
        display: 'inline-block',
        borderRadius: '8px',
        overflow: 'hidden',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
        transition: 'transform 0.3s ease, box-shadow 0.3s ease',
        cursor: 'pointer'
    },
    image: {
        display: 'block',
        width: '100%',
        height: 'auto',
        transition: 'transform 0.3s ease',
    },
    price: {
        position: 'absolute',
        bottom: '0',
        left: '0',
        right: '0',
        backgroundColor: 'rgba(105, 105, 105, 0.7)',
        color: '#fff',
        padding: '10px',
        fontSize: '16px',
        textAlign: 'center',
        fontWeight: 'bold',
        borderTop: '1px solid rgba(255, 255, 255, 0.3)',
    }
}

export default frameStyle;