import downloadBiodataStyles from "./downloadBiodata.styles";
import { downloadBiodataByDocumentId } from "./downloadBioDataService";

const DownloadBioData = () => {
    const classes = downloadBiodataStyles;
    const queryParams = new URLSearchParams(window.location.search);
    const params = {
        docId: queryParams.get('docId') || '',
        docName: queryParams.get('docName') || '',
    }

    const handleDownloadBioData = async () => {
        await downloadBiodataByDocumentId(params.docId, "quickDocSolution.pdf")
    }

    return (
        <div style={classes.container}>
            <div style={classes.card}>
                <h1 style={classes.title}>Download Marriage Biodata</h1>
                <ul style={classes.list}>
                    <li onClick={handleDownloadBioData} style={classes.listItem}><strong>Click here to download biodata</strong></li>
                </ul>
            </div>
        </div>
    )
}

export default DownloadBioData;