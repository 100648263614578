import axios from "axios";
import { enqueueSnackbar } from "notistack";

export const header = () => {
  return {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };
};

export const getRequest = async (url: string) => {
  const response = await axios.get(url, header());
  return response.data;
};

export const postRequest = async (url: string, data: any) => {
  const response = await axios.post(url, data, header());
  return response.data;
};

export const patchRequest = async (url: string, data: any) => {
  const response = await axios.patch(url, data, header());
  return response.data;
};

export const deleteRequest = async (url: string) => {
  const response = await axios.delete(url, header());
  return response.data;
};

export const downloadRequest = async (url: string, fileName: string) => {
  axios({
    url: url,
    method: "GET",
    responseType: "blob",
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  }).then((response) => {
    const href = URL.createObjectURL(response.data);
    const link = document.createElement("a");
    link.href = href;
    link.setAttribute(
      "download",
      fileName
    );
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  });
};

export const uploadRequest = async (url: string, formData: FormData) => {
  const header = {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };
  const response = await axios.post(url, formData, header);
  return response.data;
};

export const exportRequest = async (url: string, data: any) => {
  const response = await axios.post(url, data, {
    method: "GET",
    responseType: "blob",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  return response.data;
};

export const successNotification = (success: any) => {
  enqueueSnackbar(success,
    {
      variant: 'success',
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'right'
      }
    }
  );
};

export const errorNotification = (message: string) => {
  enqueueSnackbar(message,
    {
      variant: 'error',
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'right'
      }
    }
  );
};