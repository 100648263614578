import { MouseEventHandler } from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';

const CustomButton = styled(Button)(({ theme }) => ({
    backgroundColor: '#4caf50',
    color: '#fff',
    borderRadius: '12px',
    padding: '10px 20px',
    boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
    '&:hover': {
        backgroundColor: '#45a049',
        boxShadow: '0 6px 12px rgba(0,0,0,0.3)',
    },
}));

export default CustomButton;