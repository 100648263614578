import { Stack, Typography } from '@mui/material'
import React from 'react'
import Header from '../global/header/Header'
import Footer from '../global/footer/Footer'

const RefundPolicy = () => {

    const makeHeader = (text: string) => {
        return (
            <Typography fontSize='25px' fontWeight='bold' m={'25px'}>
                {text}
            </Typography>
        )
    }

    const makeBody = (text: string) => {
        return (
            <Typography variant="body2" textAlign={'justify'} align='center' sx={{
                textAlign: 'justify !important',
                hyphens: 'auto',
                wordSpacing: '-.05em',
                padding: '0 2rem',
                lineHeight: '1.6',
            }}>
                {text}
            </Typography>
        )
    }

    return (
        <>
            <Header></Header>
            <Stack >
                {makeHeader('Refund Policy')}
                {makeBody("At QuickDocSolution, we are committed to providing high-quality Marriage Biodata to our valued customers. We understand that occasionally, issues may arise that require a refund. To ensure a transparent and fair refund process, we have established the following refund policy for our intangible digital product like Marriage Biodata:")}
            </Stack>
            <Stack p={2} >
                {makeHeader('1. Refund Claim Submission Window:')}
                {makeBody("• Refund claims must be submitted via email to support@quickdocsolution.com within 24 hours of the original payment date.")}
                {makeBody("• After the 24-hour period has elapsed, no refund requests will be entertained.")}
            </Stack>
            <Stack p={2} >
                {makeHeader('2. Requirements for Refund:')}
                {makeBody("• We will make every effort to address and rectify any issues or concerns you may have with our digital products.")}
                {makeBody("• If you encounter a problem, please contact our support team immediately via email to support@quickdocsolution.com, providing a detailed description of the issue along with any relevant screenshots of files.")}
                {makeBody("• Our team will diligently work to resolve the issue to your satisfaction.")}
            </Stack>
            <Stack p={2} >
                {makeHeader('3. Refund Process:')}
                {makeBody("• If, despite our best efforts, we are unable to resolve the issue to your satisfaction, you will be eligible for a 100% refund of the purchase price.")}
                {makeBody("• Once eligible, the refund will be initiated immediately and it will take upto 4-5 business days for the credit to reflect in your account statement.")}
            </Stack>
            <Stack p={2} >
                {makeHeader('4. Refund Method:')}
                {makeBody("• Refunds will be issued using the same payment method used for the original purchase.")}
                {makeBody("• If a refund is not feasible through the original payment method, we will work with you to find an alternative refund method that is mutually acceptable.")}
                {makeBody("• Refunds will not be provided in cases where the issue arises due to user error, misuse, or violation of the terms and conditions associated with our digital products. Also, no refunds will be granted for digital products that have been downloaded, accessed, or used beyond the 24-hour refund claim window.")}
            </Stack>
            <Stack paddingBottom='20px'>
                {makeHeader('Changes to this Policy:')}
                {makeBody("We reserve the right to modify or update this refund policy at any time. Any changes will be posted on our website and will apply to all future transactions. Please carefully read and understand this refund policy before making a purchase of our digital products. By completing a purchase, you acknowledge that you have read, understood, and agreed to the terms and conditions outlined in this policy. If you have any questions or require clarification regarding our refund policy, please do not hesitate to contact our support team for assistance.")}
                {makeBody("Thank you for choosing quickdocsolution for your Marriage Biodata. We appreciate your trust in our products and services.")}
                {makeBody("QuickDocSolution")}
                {makeBody("31 July 2024")}
            </Stack>
            <Footer></Footer>
        </>
    )
}

export default RefundPolicy;