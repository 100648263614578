import { Typography } from '@mui/material';
import viewManglikStatusStyles from './viewManglikStatus.styles';

const ViewManglikStatus = () => {
  const classes = viewManglikStatusStyles;
  const queryParams = new URLSearchParams(window.location.search);

  const params = {
    name: queryParams.get('name') || '',
    birthDateTime: queryParams.get('birthDateTime') || '',
    birthCity: queryParams.get('birthCity') || '',
    gender: queryParams.get('gender') || '',
    country: queryParams.get('country') || '',
    manglik_status: queryParams.get('manglik_status') || '',
    payment_id: queryParams.get('payment_id') || '',
    payment_status: queryParams.get('payment_status') || '',
    payment_request_id: queryParams.get('payment_request_id') || ''
  }

  const manglikStatusJSX = () => {
    if (params.manglik_status) {
      return <Typography color="red" style={classes.typography}>Manglik</Typography>
    } else {
      return <Typography color="green" style={classes.typography}>Not Manglik</Typography>
    }
  }

  return (
    <div style={classes.container}>
      <div style={classes.card}>
        <h1 style={classes.title}>Manglik Details</h1>
        <ul style={classes.list}>
          <li style={classes.listItem}><strong>Name:</strong> {params.name}</li>
          <li style={classes.listItem}><strong>Date of Birth:</strong> {params.birthDateTime}</li>
          <li style={classes.listItem}><strong>Birthplace:</strong> {params.birthCity}</li>
          <li style={classes.listItem}><strong>Gender:</strong> {params.gender}</li>
          <li style={classes.listItem}><strong>Country:</strong> {params.country}</li>
          <li style={classes.listItem}><strong>Manglik Status:</strong> {manglikStatusJSX()}</li>
        </ul>
      </div>
    </div>
  );
}

export default ViewManglikStatus;