export const initialManglikFormState = {
    name: '',
    birthCity: {
        id: '',
        cityAscii: null,
        latitude: 0,
        longitude: 0,
        city: '',
        state: '',
        country: '',
        address: '',
        autocomplete: ''
    },
    birthDateTime: '',
    gender: { name: '', value: '' },
}

export const initialCityState = {
    id: '',
    cityAscii: null,
    latitude: 0,
    longitude: 0,
    city: '',
    state: '',
    country: ' ',
    address: '',
    autocomplete: '',
};