import { Box, Button, Grid, Stack, Tab, Tabs, Typography } from '@mui/material';
import React, { useState } from 'react'
import Form from './components/form/Form';
import { BioDataFormData, FontType } from '../global/Types';
import View from './components/view/View';
import Frame from './components/frame/Frame';
import Font from './components/font/Font';
import { dummyBlankFormData, dummyRealFormData } from '../DummyData';
import bioDataMakerStyles from './BioDataMakerStyles';
import { useNavigate } from 'react-router-dom';
import { generateDownloadBiodataLink } from './bioDataMakerService';
import CalculatedPricePopup from './components/calculatedPricePopup/CalculatedPricePopup';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

function TabPanel(props: any) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

const BioDataMaker = () => {
    const classes = bioDataMakerStyles;
    const navigate = useNavigate();
    const [tabValue, setTabValue] = useState(0);
    const [bioDataFormData, setFormData] = useState<BioDataFormData>(dummyBlankFormData);

    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [price, setPrice] = useState(0);


    const handleCloseDialog = () => {
        setIsDialogOpen(false);
    };

    const handleChange = (event: any, newValue: any) => {
        setTabValue(newValue);
    };

    const handleFrameChange = (selectedFrame: any) => {
        setFormData({ ...bioDataFormData, frame: { ...bioDataFormData.frame, name: selectedFrame.name, price: selectedFrame.price } })
    }

    const handleFontChange = (font: FontType) => {
        setFormData({ ...bioDataFormData, font: font })
    }

    const tabs = () => {
        return (
            <>
                <Grid container sx={classes.header}>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4} p={2} style={{ cursor: 'pointer' }} onClick={() => navigate('/')}>
                        <a style={classes.headerHyperlink} >
                            Quick
                        </a>
                        <a style={{ ...classes.headerHyperlink, color: 'tomato' }} >
                            Doc
                        </a>
                        <a style={classes.headerHyperlink} >
                            Solutions
                        </a>
                        <a style={{ ...classes.headerHyperlink, fontSize: "15px" }} >
                            .com
                        </a>
                    </Grid>
                    <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                        <Tabs
                            value={tabValue}
                            onChange={handleChange}
                            aria-label="tabs example"
                        >
                            <Tab label="Details" id="tab-0" aria-controls="tabpanel-0" />
                            <Tab label="Frame" id="tab-1" aria-controls="tabpanel-1" />
                            <Tab label="Font" id="tab-2" aria-controls="tabpanel-2" />
                        </Tabs>
                    </Grid>
                </Grid>
            </>
        )
    }

    const view = () => {
        return <>

            <Stack className='viewCotainer' display='flex' flexDirection='row' justifyContent='center'>
                <View paddingTop='28px' photoHeight='80px' fieldSize="12px" subFieldSize='10px' formData={bioDataFormData} height={465} width={316}></View>
            </Stack>
            <div style={{
                position: 'absolute',
                left: '-9999px'
            }}>
                <View paddingTop='90px' secondPagepaddingTop='90px' photoHeight='200px' fieldSize="30px" subFieldSize='25px' className='bio-data-page' formData={bioDataFormData} height={1122} width={793}></View>
            </div>
            <div ><Button sx={classes.downloadButton} onClick={() => setIsDialogOpen(true)}>Download PDF</Button></div>
        </>
    }

    const downloadBioData = async (totalPayable: number) => {
        try {

            const config = { scale: 0.1, useCORS: true }

            const doc = new jsPDF('p', 'mm', 'a4');
            const canvas1: any = await html2canvas(document.querySelector('.bio-data-page1') as any, config);
            const a4WidthMM = 210;
            const a4HeightMM = 297;
            const canvasWidth = canvas1.width;
            const canvasHeight = canvas1.height;
            const scaleX = a4WidthMM / (canvasWidth / 2);
            const scaleY = a4HeightMM / (canvasHeight / 2);
            const scale = Math.min(scaleX, scaleY);
            const imgWidth = canvasWidth / 2 * scale;
            const imgHeight = canvasHeight / 2 * scale;
            doc.addImage(canvas1.toDataURL('image/png'), 'PNG', 0, 0, imgWidth, imgHeight);

            const page2 = document.querySelector('.bio-data-page2');
            if (page2) {
                doc.addPage();
                const canvas3: any = await html2canvas(page2 as any, config);
                doc.addImage(canvas3.toDataURL('image/png'), 'PNG', 0, 0, imgWidth, imgHeight);
            }

            const page3 = document.querySelector('.bio-data-page3');
            if (page3) {
                doc.addPage();
                const canvas3: any = await html2canvas(page3 as any, config);
                doc.addImage(canvas3.toDataURL('image/png'), 'PNG', 0, 0, imgWidth, imgHeight);
            }

            if (totalPayable === 0) {
                doc.save('bio-data');
            } else {
                const pdfBlob = doc.output('blob');

                const formData = new FormData();
                formData.append('file', pdfBlob, 'bio-data.pdf');
                formData.append('amount', totalPayable.toString());

                const generatedBioDataDownloadLink = await generateDownloadBiodataLink(formData);
                window.location.href = generatedBioDataDownloadLink.longurl;
            }
        } catch (error) {
            console.error('Error occurred while fetching the payment URL:', error);
        }
    }

    return (
        <Grid container>
            <Grid className='fill-form' item xs={12} sm={12} md={12} lg={12} xl={12}>
                {tabs()}
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6} style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                <TabPanel value={tabValue} index={0}>
                    <Form formData={bioDataFormData} setFormData={setFormData}></Form>
                </TabPanel>
                <TabPanel value={tabValue} index={1}>
                    <Frame handleFrameChange={handleFrameChange}></Frame>
                </TabPanel>
                <TabPanel value={tabValue} index={2}>
                    <Font handleFontChange={handleFontChange}></Font>
                </TabPanel>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6} style={{ display: 'flex', justifyContent: 'space-around', flexDirection: 'column' }}>
                {view()}
            </Grid>
            <CalculatedPricePopup open={isDialogOpen} onClose={handleCloseDialog} handlePayAndDownloadButton={downloadBioData} formData={bioDataFormData} />
        </Grid>
    )
}

export default BioDataMaker;