import React, { lazy } from 'react';
import { Button, Grid, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import homeStyles from './Home.styles';
const FastForwardIcon = lazy(() => import('@mui/icons-material/FastForward'));
const Header = React.lazy(() => import('../global/header/Header'))
const Footer = React.lazy(() => import('../global/footer/Footer'))

const Home = () => {
    const classes = homeStyles;
    const navigate = useNavigate();

    const makeHeader = (text: string) => {
        return (
            <Typography fontSize='25px' fontWeight='bold' textAlign='center' m={'80px'}>
                {text}
            </Typography>
        )
    }

    const makeBody = (text: string) => {
        return (
            <Typography variant="body2" textAlign={'justify'} align='center' sx={classes.body}>
                {text}
            </Typography>
        )
    }

    const home = () => {
        return (
            <Stack style={classes.mainStack} >
                <Header></Header>
                <Stack flexDirection='column' justifyContent='right' paddingTop='2px'>
                    <Grid container style={classes.section}>
                        <Grid padding='7vh 2vh 2vh 2vh' item xs={12} sm={12} md={6} lg={6} xl={6} display='flex' flexDirection='column' justifyContent='center' alignItems='flex-start'>
                            <Typography fontSize='30px' fontWeight='bolder' >Marriage Bio Data Format<br />and Newest Marriage<br />Biodata Formats</Typography>
                            <Typography fontSize='15px'>Create, Customize & download biodata in few clicks.<br />Making biodata made easy. Try now.<br />No Sign up | No Registration required<br /><br /></Typography>
                            <Button variant='contained' color='error' size='large' endIcon={<FastForwardIcon />} onClick={() => navigate('/create-bio-data')}><Typography variant='button'>Create Biodata Now</Typography></Button>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6} >
                            <img
                                style={classes.sampleImage}
                                srcSet={`/images/home/sample-bio-data.png?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                                src={`/images/home/sample-bio-data.png??w=161&fit=crop&auto=format`}
                                alt={`sample-image`}
                                loading="lazy"
                            />
                        </Grid>
                    </Grid>
                </Stack >
                <Stack>
                    <Grid >
                        {makeHeader('Why Choose Our Marriage Biodata Maker')}
                        {makeBody('In today’s fast-paced digital world, safeguarding your personal information is a top priority. At QuickDocSolutions.com, we’re all about your privacy, our local-only approach ensures your biodata stays right where it belongs, on your device. With our cutting edge algorithm, you have the power to customize your biodata effortlessly, add or remove fields to fit your needs. Plus, sharing your marriage biodata with loved ones is now a breeze send it directly via email without any hassle. Experience the ultimate in secure, stylish, and seamless biodata creation today!')}
                        <Stack className='sdjbfuids' flexDirection='row' justifyContent='space-around'>
                            <img
                                style={classes.card}
                                srcSet={`${'images/home/why-quickdocsolution.png'}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                                src={`${'images/home/why-quickdocsolution.png'}?w=161&fit=crop&auto=format`}
                                alt={'No Data Saved'}
                                loading="lazy"
                            />
                        </Stack>
                    </Grid>
                </Stack>
                <Stack >
                    {makeHeader('Steps to Create and Download Marriage Biodata')}
                    <Stack className='sdjbfuids' flexDirection='row' justifyContent='space-around'>
                        <img
                            style={classes.card}
                            srcSet={`${'images/home/steps.png'}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                            src={`${'images/home/steps.png'}?w=161&fit=crop&auto=format`}
                            alt={'No Data Saved'}
                            loading="lazy"
                        />
                    </Stack>
                    {makeBody("Making a marriage biodata that looks good is the initial step towards discovering the right life mate. But are you looking for a biodata format, or are you not sure how to construct a marital biodata? If so, you've arrived at the ideal location. Using a desktop, laptop, or smartphone, QuickDocSolution.com makes it simple for you to prepare the best marriage biodata online. These days, creating a biodata is really easy. After entering the necessary data and choosing the template, download the biodata as a PDF file. Click the checkboxes next to the fields you wish to fill out, then input the required data to complete the Personal Details section. If you need to make any changes to a field, just click the edit symbol. Just to recap.")}
                </Stack>
                <Footer></Footer>
            </Stack>
        )
    }

    return home();
}

export default Home;