export const countLines = (data: any) => {
    let totalLine = 0;
    for (let i = 0; i < data.length; i++) {
        const item = data[i];
        let innerLine = 0;
        for (const subfieldItem of item.subfield) {
            if (subfieldItem.visibility) {
                innerLine += 1;
            }
        }
        totalLine += innerLine;
    }
    totalLine += data.length;
    return totalLine;
};