import { checkManglikStatus, searchBirthCityDetail } from './manglikStatusService';
import { Container, Grid, TextField } from '@mui/material';
import { initialCityState, initialManglikFormState } from './manglikStatusInitialData';
import { City, FormData } from './manglikStatus.types';
import { CustomHeading } from './customHeading/CustomHeading';
import { useState } from 'react'
import CustomDateTimePicker from '../../../global/components/CustomDateTimePicker';
import manglikStatusStyles from './manglikStatus.styles';
import CustomAutoComplete from './customAutoComplete/CustomAutoComplete';
import CustomTextField from './customTextField/CustomTextField';
import CustomButton from './customButton/CustomButton';
import { errorNotification } from '../../../global/utils';

const inputFieldWidth = 250;
const customAutoCompleteBackground = "#FFFFFF";
const dropdownWidth = 250;
const dateTimePickerWidth = 220;

const genderOptions = [{ name: "Male", value: "male" }, { name: "Female", value: "female" }, { name: "Other", value: "other" }];

const ManglikStatus = () => {
    const classes = manglikStatusStyles;
    const [filteredCities, setFilteredCities] = useState<City[]>([]);
    const [searchTimeout, setSearchTimeout] = useState<NodeJS.Timeout | null>(null);
    const [formData, setFormData] = useState<FormData>(initialManglikFormState);

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFormData({ ...formData, [event.target.name]: event.target.value })
    };

    const handleBirthCityChange = (value: any) => {
        if (value) {
            setFormData({ ...formData, birthCity: value })
        } else {
            setFormData({ ...formData, birthCity: initialCityState })
        }
    };

    const handleSearchBirthPlace = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const searchText = event.target.value;
        if (searchTimeout) {
            clearTimeout(searchTimeout);
        }
        const timeout = setTimeout(async () => {
            if (searchText) {
                try {
                    let fetchPlace = await searchBirthCityDetail(searchText);
                    setFilteredCities(fetchPlace.results);
                } catch (error: any) {
                    errorNotification(error?.response?.data?.error)
                }
            } else {
                setFormData({ ...formData, birthCity: initialCityState })
            }
        }, 600);
        setSearchTimeout(timeout);
    };

    const handleGenderChange = (value: any) => {
        setFormData({ ...formData, gender: { name: value.name, value: value.value } })
    }

    const contructPayload = (formData: FormData) => {
        const bithDateTime = new Date(formData.birthDateTime);
        return {
            apiName: 'manglik',
            userData: {
                nameu: formData.name,
                birth: formData.birthCity.city,
                day: bithDateTime.getDate(),
                month: bithDateTime.getMonth() + 1,
                year: bithDateTime.getFullYear(),
                min: bithDateTime.getMinutes(),
                hour: bithDateTime.getHours(),
                language: 'english',
                gender: formData.gender.value,
                tzone: 5.5,
                country: formData.birthCity.country,
                lat: formData.birthCity.latitude,
                lon: formData.birthCity.longitude
            }
        }
    }

    const handleValidation = () => {
        let message = ' - ';
        let validated = true;
        if (!formData.name) {
            message = 'Please Enter Your Name';
            validated = false;
        } else if (!formData.birthDateTime) {
            message = 'Please Select Your Birth Date and Time';
            validated = false;
        } else if (!formData.birthCity.city) {
            message = 'Please Select Your Birth City';
            validated = false;
        } else if ((!formData.gender.value)) {
            message = 'Please Select Your Gender';
            validated = false;
        } else if (!formData.birthCity.country) {
            message = 'Country is Empty';
            validated = false;
        } else if (!formData.birthCity.latitude) {
            message = 'Lattitude is Empty';
            validated = false;
        } else if (!formData.birthCity.longitude) {
            message = 'Longitude is Empty';
            validated = false;
        }
        if (!validated) {
            errorNotification(message);
        }
        return validated;
    }

    const handleSubmit = async (event: any) => {
        try {
            if (handleValidation()) {
                const payload = contructPayload(formData);
                const response: any = await checkManglikStatus(payload);
                window.location.href = response.longurl;
            }
        } catch (error: any) {
            errorNotification(error?.response?.data?.error);
        }
        event.stopPropagation();
    };

    return (
        <Container maxWidth="sm" style={classes.container}>
            <CustomHeading variant="h4" gutterBottom>
                Check Manglik Status
            </CustomHeading>
            <Grid className="testtt" container spacing={2} display="flex" flexDirection="column" alignContent="center">
                <Grid item xs={12}>
                    <CustomTextField
                        style={{ width: inputFieldWidth }}
                        label="Name"
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleInputChange}
                    />
                </Grid>
                <Grid item xs={12}>
                    <CustomDateTimePicker
                        name="birthDateTime"
                        width={dateTimePickerWidth}
                        label="Birth Date & Time"
                        value={formData.birthDateTime}
                        handleChange={handleInputChange}
                    />
                </Grid>
                <Grid item xs={12}>
                    <CustomAutoComplete
                        width={dropdownWidth}
                        value={formData.birthCity}
                        options={filteredCities}
                        getOptionLabel={(option: any) => option.city}
                        onChange={handleBirthCityChange}
                        renderInput={(params: any) => (
                            <TextField
                                onChange={handleSearchBirthPlace}
                                {...params}
                                label="Birth City"
                                variant="outlined"
                                style={{ width: dropdownWidth, background: customAutoCompleteBackground }}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12}>
                    <CustomAutoComplete
                        width={dropdownWidth}
                        value={formData.gender}
                        options={genderOptions}
                        getOptionLabel={(option: any) => option.name}
                        onChange={handleGenderChange}
                        renderInput={(params: any) => (
                            <TextField
                                {...params}
                                label="Gender"
                                variant="outlined"
                                style={{ width: dropdownWidth, background: customAutoCompleteBackground }}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12}>
                    <CustomTextField
                        style={{ width: inputFieldWidth }}
                        label="Timezone"
                        type="text"
                        value="Asia/Kolkata"
                        name="tzone"
                        disabled={true}
                    />
                </Grid>
                <Grid item xs={12}>
                    <CustomTextField
                        style={{ width: inputFieldWidth }}
                        label="Country"
                        type="text"
                        name="country"
                        disabled={true}
                        value={formData.birthCity?.country}
                    />
                </Grid>
                <Grid item xs={12}>
                    <CustomTextField
                        style={{ width: inputFieldWidth }}
                        label="Latitude"
                        type="text"
                        name="latitude"
                        disabled={true}
                        value={formData.birthCity?.latitude}
                    />
                </Grid>
                <Grid item xs={12}>
                    <CustomTextField
                        style={{ width: inputFieldWidth }}
                        label="Longitude"
                        type="text"
                        name="longitude"
                        disabled={true}
                        value={formData.birthCity?.longitude}
                    />
                </Grid>
            </Grid>
            <div style={{ padding: '20px', textAlign: 'center' }}>
                <CustomButton onClick={handleSubmit}>Submit</CustomButton>
            </div>
        </Container>
    )
}

export default ManglikStatus;