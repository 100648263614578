import { useTheme } from '@emotion/react';
import { Box, Button, Grid, ImageList, ImageListItem, Paper, Typography } from '@mui/material';
import { textAlign } from 'html2canvas/dist/types/css/property-descriptors/text-align';
import styled from 'styled-components';

const StyledButton = styled.a`
  display: inline-block;
  padding: 12px 24px;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  background: linear-gradient(135deg, #ff6b6b, #f06595);
  border-radius: 4px;
  text-align: center;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.5);
  transition: background 0.3s, transform 0.3s;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    background: linear-gradient(135deg, #f06595, #ff6b6b);
    // transform: scale(1.05);
  }

  &:focus {
    outline: 2px solid #ff6b6b;
    outline-offset: 2px;
  }
`;

const fonts = [
    { id: 1, fontFamily: 'Times', fontStyle: '', fontWeight: '' },
    { id: 2, fontFamily: 'Times', fontStyle: '', fontWeight: 'bold' },
    { id: 3, fontFamily: 'Times', fontStyle: 'italic', fontWeight: '' },
    { id: 4, fontFamily: 'Times', fontStyle: 'italic', fontWeight: 'bold' },

    { id: 5, fontFamily: 'Helvetica', fontStyle: '', fontWeight: '' },
    { id: 6, fontFamily: 'Helvetica', fontStyle: '', fontWeight: 'bold' },
    { id: 7, fontFamily: 'Helvetica', fontStyle: 'italic', fontWeight: '' },
    { id: 8, fontFamily: 'Helvetica', fontStyle: 'italic', fontWeight: 'bold' },
];

const Font = ({ handleFontChange }: any) => {
    const theme = useTheme();

    const handleLogoClick = (font: any) => {
        handleFontChange(font);
    };

    const getSubTitle = (font: any) => {
        let subTitle = '';
        if (!font.fontWeight && !font.fontStyle) {
            subTitle = 'Normal';
        } else {
            subTitle = font.fontWeight + font.fontStyle;
        }
        return subTitle;
    }

    return (
        <Paper elevation={24}>
            <ImageList >
                {fonts.map((font: any) => (
                    <ImageListItem key={font.id} >
                        <StyledButton onClick={() => handleLogoClick(font)}>
                            <Box style={{ textAlign: 'center', fontFamily: font.fontFamily, fontWeight: font.fontWeight, fontStyle: font.fontStyle }}>
                                <Typography style={{ fontFamily: font.fontFamily, fontWeight: font.fontWeight, fontStyle: font.fontStyle }}>{font.fontFamily}</Typography>
                                <Typography style={{ fontFamily: font.fontFamily, fontWeight: font.fontWeight, fontStyle: font.fontStyle }}>{getSubTitle(font)}</Typography>
                            </Box>
                        </StyledButton>
                    </ImageListItem>
                ))}
            </ImageList>
        </Paper >
    )
}

export default Font;