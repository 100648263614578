import { styled, TextField } from '@mui/material';

const CustomTextField = styled(TextField)(({ theme }) => ({
    '& .MuiOutlinedInput-root': {
        borderRadius: '8px',
        backgroundColor: '#ffffff',
        border: `1px solid ${theme.palette.grey[300]}`,

        '& input': {
            padding: '10px',
            fontSize: '1rem',
        },

        '& fieldset': {
            borderColor: 'transparent',
        },

        '&:hover fieldset': {
            borderColor: theme.palette.primary.main,
        },

        '&.Mui-focused fieldset': {
            borderColor: theme.palette.primary.main,
            boxShadow: `0 0 5px ${theme.palette.primary.main}50`,
        },
    },

    '& .MuiInputLabel-root': {
        color: theme.palette.text.secondary,
        fontWeight: 500,
        fontSize: '0.875rem',
        transition: 'color 0.3s ease',
    },

    '& .MuiInputLabel-root.Mui-focused': {
        color: theme.palette.primary.main,
    },

    '& .MuiInputBase-input::placeholder': {
        color: theme.palette.text.disabled,
    },
}));

export default CustomTextField;