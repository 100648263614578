const bioDataMakerStyles = {
    header: {
        display: 'flex',
        alignItems: 'center'
    },
    headerHyperlink: {
        color: 'black',
        textDecoration: 'none',
        fontWeight: 'bold',
        fontSize: '22px',

        alignItems: 'center',
        fontFamily: 'Roboto',
    },
    downloadButton: {
        position: 'fixed',
        bottom: '20px',
        right: '20px',
        zIndex: 1000,
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '10px 20px',
        fontSize: '16px',
        fontWeight: 'bold',
        color: '#fff',
        background: 'linear-gradient(135deg, #ff6b6b, #a777e3)',
        border: 'none',
        borderRadius: '8px',
        cursor: 'pointer',
        transition: 'transform 0.2s ease, box-shadow 0.3s ease, background 0.3s ease',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
        '&:hover': {
            background: 'linear-gradient(135deg, #f06595, #ff6b6b)',
            transform: 'scale(1.05)',
        }
    }
}

export default bioDataMakerStyles;