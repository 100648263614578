import React, { useState } from 'react';
import { Dialog, DialogContent, Grid, IconButton, Avatar, Typography, Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const logos = [
  { id: 1, src: '/images/god-icon/1.png', alt: 'Logo 1' },
  { id: 2, src: '/images/god-icon/2.png', alt: 'Logo 2' },
  { id: 3, src: '/images/god-icon/3.png', alt: 'Logo 3' },
  { id: 4, src: '/images/god-icon/4.png', alt: 'Logo 4' },
  { id: 5, src: '/images/god-icon/5.png', alt: 'Logo 5' },
  { id: 6, src: '/images/god-icon/6.png', alt: 'Logo 6' },
  { id: 7, src: '/images/god-icon/7.png', alt: 'Logo 7' },
  { id: 8, src: '/images/god-icon/8.png', alt: 'Logo 8' },
  { id: 9, src: '/images/god-icon/9.png', alt: 'Logo 9' },
  { id: 10, src: '/images/god-icon/10.png', alt: 'Logo 10' },
  { id: 11, src: '/images/god-icon/11.png', alt: 'Logo 11' },
  { id: 12, src: '/images/god-icon/12.png', alt: 'Logo 12' },
  { id: 13, src: '/images/god-icon/13.png', alt: 'Logo 13' },
  { id: 14, src: '/images/god-icon/14.png', alt: 'Logo 14' },
  { id: 15, src: '/images/god-icon/15.png', alt: 'Logo 15' },
];

const LogoPicker = ({ open, onClose, onSelect }: any) => {
  const theme = useTheme();

  const handleLogoClick = (logo: any) => {
    onSelect(logo);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} >
      <DialogContent>
        <Grid container spacing={2} sx={{
          display: 'flex',
          margin: '0.5rem',
          paddingLeft: '2rem',
          flexWrap: 'wrap',
          width: '400px',
          justifyContent: 'space-between',
        }}>
          {logos.map((logo) => (
            <Grid item xs={4} key={logo.id}>
              <IconButton
                onClick={() => handleLogoClick(logo)}
                sx={{ width: 50, height: 50, p: 0 }}
              >
                <Avatar src={logo.src} alt={logo.alt} sx={{
                  height: '48px',
                  margin: '7px',
                  width: '48px',
                  borderRadius: '50%',
                  padding: '5px',
                  boxShadow: '0 3px 12px rgb(0 0 0 / 9%), 0 1px 5px 0 rgb(0 0 0 / 13%);'
                }} />
              </IconButton>
            </Grid>
          ))}
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default LogoPicker;